<template>
  <v-dialog
    v-model="dialogLocal"
    max-width="450px"
    @click:outside="dialogLocal = false"
    @keydown="dialogLocal = false"
  >
    <v-card>
      <v-card-title class="headline">
        <slot name="title" />
      </v-card-title>
      <v-card-text>
        <v-select
          no-gutters
          outlined
          :items="listaTipos"
          label="Selecione o Tipo"
          v-model="tipo"
          :prepend-inner-icon="iconePorTipo[tipo]"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          text
          @click="dialogLocal = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="green darken-1"
          @click="$emit('submitTriggered', tipo.toLowerCase())">
          Baixar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    listaTipos: ['XLSX', 'CSV'],
    tipo: 'XLSX',
    iconePorTipo: {
      "XLSX": "mdi-microsoft-excel",
      "CSV": "mdi-note-text-outline"
    }
  }),

  computed: {
    dialogLocal: {
      get() {
        return this.dialog;
      },
      set(newValue) {
        this.$emit('update:dialog', newValue);
      }
    }
  }
}
</script>